import React, {useState, useEffect} from "react";
import { coloredClassName, okButton } from '../../styleClassNames';
import {Alert, Row, Card, Space, Tag, Col, Statistic, Skeleton, Table, Button} from "antd";
import CrudIntegration from "../../logic/crudIntegration";


const statLabels = [
    {label: 'No.Products', value: 'no_products', size: 4},
    {label: 'No. Customers', value: 'no_customers', size: 4},
    {label: "Today' Customers", value: 'no_today_customers', size: 4},
    {label: 'Incomplete Profiles', value: 'no_incomplete_profiles', size: 4},
    
    {label: 'Active Policies', value: 'no_active_policies', size: 4},
    {label: 'Inactive Policies', value: 'no_inactive_policies', size: 4},
    {label: 'Active Lives', value: 'no_active_lives', size: 4},
    {label: 'Inactive Lives', value: 'no_inactive_lives', size: 4},
    
    {label: 'Outstanding Claims', value: 'outstanding_claims', size: 4},
    {label: 'Claim Amount Paid', value: 'claims_paid', size: 6},
    {label: 'Annual Premiums', value: 'annual_premiums', size: 6},
    {label: 'Annual Commission', value: 'annual_commission', size: 6},
    {label: 'Fraud Alerts', value: 'no_fraud_alerts', size: 4},
    
]

const ovaColumns = [
    {title: 'Account Owner', dataIndex: 'owner', key: 'owner'},
    {title: 'Account Type', dataIndex: 'account_type', key: 'account_type'},
    {title: 'Cum. Credit', dataIndex: 'cum_credit', key: 'cum_credit', render: (text) => <span>{Number(text).toLocaleString()}</span>},
    {title: 'Cum. Debit', dataIndex: 'cum_debit', key: 'cum_debit', render: (text) => <span>{Number(text).toLocaleString()}</span>},
    {title: 'Balance', dataIndex: 'actual_balance', key: 'actual_balance', render: (text) => <span>{Number(text).toLocaleString()}</span>},
    {
        title: 'Actions',
        dataIndex: 'actual_balance',
        key: 'actual_balance',
        render: (text, record) => 
        <Button disabled title="Single Lower Transaction Amount Entry detected. E.O.D Operations disabled until Exceptional Clearance is Approved.">Record Disbursement</Button>
    },

]


const Overview = (props) => {
    const [ fetching, setFetching ] = useState(true)
    const [stats, setStats] = useState({});
    const [ovas, setOvas] = useState([]);

    const getDashboardValues = async () => {
        const stats = await new CrudIntegration().get(
            'api/v1/admin-portal/dashboard/'
        );
        console.log(stats)
        if (stats !== 400){
            setStats(stats.insight);
            setOvas(stats.ovas);
        }    
        setFetching(false);
    };
    
    useEffect(() => { getDashboardValues()}, []);


    return(
        <React.Fragment>
            <h3 style={{fontSize:'1.875rem', marginBottom: '20px'}} className={coloredClassName}>Overview</h3>
            {
                fetching ? <Skeleton active />:
                <Row style={{marginBottom: '10px'}} gutter={5}>
                    {
                        statLabels.map((st, index) => {
                            return(
                                <Col xs={24} md={st.size} lg={st.size} style={{marginBottom: '10px'}}>
                                    <Card>
                                    <Statistic title={st.label} value={Number(stats[st.value]).toLocaleString()}/>
                                    </Card>
                                </Col>
                            )
                        })
                    }
                </Row>
            }

            
            {/* <h4 style={{fontSize:'1.0rem', marginBottom: '20px'}} className={coloredClassName}>Performance over the past 12 months</h4> */}
            {/* {
                fetching ? <Skeleton active />:
                <Row style={{marginBottom: '10px'}} gutter={10}>
                    <SimpleLineGraph />
                </Row>
            } */}

        <h4 style={{fontSize:'1.5rem', marginBottom: '20px'}} className={coloredClassName}>Account(s) Standing</h4>    
        <Table
            style={{ marginTop: "20px" }}
            dataSource={ovas}
            columns={ovaColumns}
            bordered
            pagination={false}
        />
            

        </React.Fragment>
    )
}

export default Overview;